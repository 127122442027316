import axios from '@/libs/axios'

class DataService {
    

    getDashboardCount() {
        return axios.get(`/dashboard/counts`);
    }

    getOrderCount() {
        return axios.get(`/orders/moving/active/count`);
    }

    getServiceCount() {
        return axios.get(`/orders/moving/active/count`);
    }

    getCustomerCount() {
        return axios.get("/users/count?accountType=0");
    }

    getProviderCount() {
        return axios.get("/users/count?accountType=1");
    }

    getAdminCount() {
        return axios.get("/users/count?accountType=2");
    }
}
export default new DataService();